import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "../App.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "font-awesome/css/font-awesome.min.css";
import * as Config from "../common/Config";

function Sidebar() {
  const navigate = useNavigate();
  const [isActive, setActive] = useState("false");
  const [companyDetails, setCompanyDetails] = useState("");
  const [defaultName, setDefaultName] = useState();
  // const [roleId, setRoleId] = useState()
  const [displayhColorPicker, setdisplayhColorPicker] = useState("#000");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  var roleId = localStorage.getItem("roleId");
  var adminId = localStorage.getItem("adminId");
  var email = localStorage.getItem("email");

  console.log("email", email);
  // const colorCode = useRef();
  const activeClass = () => {
    setActive(!isActive);
  };

  useEffect(() => {

    axios({
      method: "get",
      url: `${Config.base_url}admin/system_company`,
    }).then(function (response) { 
      setCompanyDetails(response.data.data);
      document.getElementById("title").innerText = response.data.data[0].name;
        

    });

  
  }, [displayhColorPicker]);

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/admin");
  const splitLocation1 = pathname.split("/admin");
  const locationname = window.location.host
 
  const AddUrl = (name) => {
    return (document.getElementById("title").innerText = name.innerText);
  };

  function getFaviconEl() {
    return document.getElementById("favicon");
  }

  if (roleId === "4" && pathname === "/admin/dashboard") {
    navigate("/signals");
  }

  const getThemeColors = () => {
    axios({
      method: "get",
      url: `${Config.base_url}admin/themecolors`,
    }).then(function (response) {
      let colorcode = response.data.theme_color_code[0];
    });
  };

  return (
    <>
      <div className="sidebar small " id="mySidebar" data-color="orange" style={{ height: '100vh' }}>
        <div className="logo">

          {roleId == "4" ?
            (<NavLink to="/admin/signals">
              <img
                className="simple-text logo-normal fix-image"
                src='/images/ttt.png'
              />

            </NavLink>) :
            (<NavLink to="/admin/dashboard">
              <img
                className="logo-normal fix-image"
                src='/images/ttt.png'
              />
              {/* <img
            className="simple-text logo-normal fix-image"
            src={`/images/${companyDetails && companyDetails[0].image}`}
          />*/}
            </NavLink>)
          }
          {/* || locationname == '180.149.241.128:3000' */}

          {(locationname == 'test.smartalgo.in' || locationname == 'client.quickalgoplus.in' || locationname == 'software.chartology.in' || locationname == 'software.algoitech.com' || locationname == 'software.adonomist.com' || locationname == '180.149.241.128:3000') &&

            <div>
              <span className="ms-5 text-light">{companyDetails && companyDetails[0].withbroker}</span><br />
              <span className="ms-5 text-light">{companyDetails && companyDetails[0].versions}</span>
            </div>}

        </div>
        <div className="sidebar-wrapper" id="sidebar-wrapper" >
          <PerfectScrollbar component="div">
            <ul className="nav">
              {roleId == "4" ? (
                ""
              ) : (
                <li className={splitLocation1[1] == "/dashboard" ? "active" : ""}
                  name="dashboard"
                  onClick={(e) => AddUrl(e.target)}
                >
                  <NavLink to="/admin/dashboard">
                    <i className={splitLocation1[1] == "/dashboard" ? "fa fa-home sidebarcolor" : "fa fa-home text-white"}></i>
                    <p className={splitLocation1[1] == "/dashboard" ? "sidebarcolor " : "text-white"}>Dashboard</p>
                  </NavLink>
                </li>
              )}
              {roleId == "4" || adminId == "106" ? (
                ""
              ) : (
                <li
                  className={splitLocation[1] == "/subadmin" ? "active" : ""}
                  name="subadmin"
                  onClick={(e) => AddUrl(e.target)}
                >
                  <NavLink to="/admin/subadmin">
                    <i className={splitLocation[1] == "/subadmin" ? "fa fa-user  sidebarcolor" : "fa fa-user text-white"}></i>
                    <p className={splitLocation[1] == "/subadmin" ? "sidebarcolor " : "text-white"}>Sub-Admin</p>
                  </NavLink>
                </li>
              )}
              {`${Config.panel_name}` == 'startalgo' || `${Config.panel_name}` == 'smartalgo' ?

                roleId == "4" ? (
                  ""
                ) : (
                  <li
                    className={splitLocation[1] == "/subadminclients" ? "active" : ""}
                    name="subadmin"
                    onClick={(e) => AddUrl(e.target)}
                  >
                    <NavLink to="/admin/subadminclients">
                      <i className={splitLocation[1] == "/subadminclients" ? "fa fa-user  sidebarcolor" : "fa fa-user text-white"}></i>
                      <p className={splitLocation[1] == "/subadminclients" ? "sidebarcolor " : "text-white"}>Sub-Admin Client</p>
                    </NavLink>
                  </li>
                ) : ""}

              {adminId == "106" ? "" :
                <li
                  className={splitLocation[1] == "/signals" ? "active" : ""}
                  onClick={(e) => AddUrl(e.target)}
                >
                  <NavLink to="/admin/signals">
                    <i className={splitLocation[1] == "/signals" ? "fa fa-signal  sidebarcolor" : "fa fa-signal text-white"}></i>
                    <p className={splitLocation[1] == "/signals" ? "sidebarcolor " : "text-white"}> Signals</p>
                  </NavLink>
                </li>}

              {/*  For Only Sub- ADmin */}

              {roleId == "4" ?
                <li
                  className={splitLocation1[0] == "/subadmin/clientlist" ? "active" : ""}
                  onClick={(e) => AddUrl(e.target)}
                >
                  <NavLink to="/subadmin/clientlist">
                    <i class={splitLocation1[0] == "/subadmin/clientlist" ? "  fa-solid fa-list-check  sidebarcolor" : "  fa-solid fa-list-check text-white"}></i>
                    <p className={splitLocation1[0] == "/subadmin/clientlist" ? "sidebarcolor " : "text-white"}>Client List</p>
                  </NavLink>
                </li> : ""}


              {roleId == "4" || adminId == "106" ? "" :
                <li
                  className={splitLocation[1] == "/system" ? "active" : "text-"}
                  onClick={(e) => AddUrl(e.target)}
                >
                  <NavLink to="/admin/system">
                    <i className={splitLocation[1] == "/system" ? "fa fa-asterisk  sidebarcolor" : "fa fa-asterisk text-white"} ></i>
                    <p className={splitLocation[1] == "/system" ? "sidebarcolor " : "text-white"}>System</p>
                  </NavLink>
                </li>}

              {roleId == "4" || adminId == "106" ? (
                ""
              ) : (
                <li
                  className={splitLocation[1] == "/strategy" ? "active" : ""}
                  onClick={(e) => AddUrl(e.target)}
                >
                  <NavLink to="/admin/strategy">
                    <i className={splitLocation[1] == "/strategy" ? "fas fa-chess-board  sidebarcolor" : "fas fa-chess-board text-white"} ></i>
                    <p className={splitLocation[1] == "/strategy" ? "sidebarcolor " : "text-white"}>Strategy</p>
                  </NavLink>
                </li>
              )}
              {roleId == "4" || adminId == "106" ? "" :
                <li
                  className={splitLocation[1] == "/services" ? "active" : ""}
                  onClick={(e) => AddUrl(e.target)}
                >
                  <NavLink to="/admin/services">
                    <i className={splitLocation[1] == "/services" ? "fa fa-wrench  sidebarcolor" : "fa fa-wrench text-white"} ></i>
                    <p className={splitLocation[1] == "/services" ? "sidebarcolor " : "text-white"}>Services</p>
                  </NavLink>
                </li>}
              {/* {roleId == "4" ? (
                ""
              ) : (
                <li
                  className={
                    splitLocation[1] == "/group-services" ? "active" : ""
                  }
                  onClick={(e) => AddUrl(e.target)}
                >
                  <NavLink to="/admin/group-services">
                    <i className={splitLocation[1] == "/group-services" ? "fa fa-cogs  sidebarcolor" : "fa fa-cogs text-white"} ></i>
                    <p className={splitLocation[1] == "/group-services" ? "sidebarcolor " : "text-white"}>Group Services</p>
                  </NavLink>
                </li>
              )} */}
              {/* {roleId == "4" || adminId == "106" ? (
                ""
              ) : (

                <li
                  className={splitLocation[1] == "/reports" ? "active" : ""}
                  onClick={(e) => AddUrl(e.target)}
                >
                  <NavLink to="/admin/reports">
                    <i className={splitLocation[1] == "/reports" ? "fa fa-clipboard  sidebarcolor" : "fa fa-clipboard text-white"} ></i>
                    <p className={splitLocation[1] == "/reports" ? "sidebarcolor " : "text-white"}>Reports</p>
                  </NavLink>
                </li>
              )} */}

              <li
                className={splitLocation[1] == "/admin/client/add-client" ? "active" : ""}
                onClick={(e) => AddUrl(e.target)}
              >
                <NavLink to="/admin/client/add-client">
                  <i className={splitLocation[1] == "/admin/client/add-client" ? "fa fa-user-plus  sidebarcolor" : "fa fa-user-plus text-white"}></i>
                  <p className={splitLocation[1] == "/admin/client/add-client" ? "sidebarcolor " : "text-white"}>Add Clients</p>
                </NavLink>
              </li>

              {roleId == "4" ? (
                ""
              ) : (
                <li
                  className={splitLocation[1] == "/clients" ? "active" : ""}
                  onClick={(e) => AddUrl(e.target)}
                >
                  <NavLink to="/admin/clients">
                    <i className={splitLocation[1] == "/clients" ? "fa fa-user  sidebarcolor" : "fa fa-user text-white"} ></i>
                    <p className={splitLocation[1] == "/clients" ? "sidebarcolor " : "text-white"}>Clients</p>
                  </NavLink>
                </li>
              )}








              {/* {roleId == "4" || adminId == "106" ? "" :
                <li
                  className={splitLocation[1] == "/tradehistory" ? "active" : ""}
                  onClick={(e) => AddUrl(e.target)}
                >
                  <NavLink to="/admin/tradehistory">
                    <i className={splitLocation[1] == "/tradehistory" ? "fa fa-history  sidebarcolor" : "fa fa-history text-white"} ></i>
                    <p className={splitLocation[1] == "/tradehistory" ? "sidebarcolor " : "text-white"}>Trade History</p>
                  </NavLink>
                </li>} */}

              {/* {roleId == "4" ? (
                <li
                  className={splitLocation[1] == "/clientlist" ? "active" : ""}
                  onClick={(e) => AddUrl(e.target)}
                >
                  <NavLink to="/subadmin/clientlist">
                    <i class="fa-solid fa-list-check"></i>
                    <p>Client List</p>
                  </NavLink>
                </li>
              ) : ( */}

              {/* (
                 <li
                   className={
                     splitLocation1[0] == "/subadmin/subtradingstatus" ? "active" : " "
                   }
                   onClick={(e) => AddUrl(e.target)}
                 >
                   <NavLink to="/subadmin/subtradingstatus">
                     <i class={splitLocation1[0] == "/subadmin/subtradingstatus" ? "fa-solid fa-stairs  sidebarcolor" : "fa-solid fa-stairs text-white"} ></i>
                     <p className={splitLocation1[0] == "/subadmin/subtradingstatus" ? "sidebarcolor" : "text-white"}>Sub Trading Status</p>
                   </NavLink>
                 </li>
               )   */}

              {/* {roleId == "4" ? ""
                : (
                  <li
                    className={
                      splitLocation[1] == "/tradingstatusadmin" ? "active" : ""
                    }
                    onClick={(e) => AddUrl(e.target)}
                  >
                    <NavLink to="/admin/tradingstatusadmin">
                      <i class={splitLocation[1] == "/tradingstatusadmin" ? "fa-solid fa-stairs  sidebarcolor" : "fa-solid fa-stairs text-white"} ></i>
                      <p className={splitLocation[1] == "/tradingstatusadmin" ? "sidebarcolor" : "text-white"}>Trading Status</p>
                    </NavLink>
                  </li>
                )} */}

              {roleId == "4" || adminId == "106" ? (
                ""
              ) : (
                <li
                  className={
                    splitLocation[1] == "/message-broadcast" ? "active" : ""
                  }
                  onClick={(e) => AddUrl(e.target)}
                >
                  <NavLink to="/admin/message-broadcast">
                    <i className={splitLocation[1] == "/message-broadcast" ? "fa fa-bullhorn  sidebarcolor" : "fa fa-bullhorn text-white"}></i>
                    <p className={splitLocation[1] == "/message-broadcast" ? "sidebarcolor" : "text-white"}>Message Broadcast</p>
                  </NavLink>
                </li>
              )}

              {/* {roleId == "4" || adminId == "106" ? (
                ""
              ) : (
                <li
                  className={
                    splitLocation[1] == "/transactionlicence" ? "active" : ""
                  }
                  onClick={(e) => AddUrl(e.target)}
                >
                  <NavLink to="/admin/transactionlicence">
                    <i className={splitLocation[1] == "/transactionlicence" ? "fa fa-exchange   sidebarcolor" : "fa fa-exchange text-white"} ></i>
                    <p className={splitLocation[1] == "/transactionlicence" ? "sidebarcolor" : " text-white"}>Transaction Licence</p>
                  </NavLink>
                </li>
              )} */}



              {roleId == "4" ? "" : <li
                className={
                  splitLocation[1] == "/ApiClientInformation" ? "active" : ""
                }
                onClick={(e) => AddUrl(e.target)}
              >
                <NavLink to="/admin/ApiClientInformation">
                  <i class={splitLocation[1] == "/ApiClientInformation" ? "fa-solid fa-info  sidebarcolor" : "fa-solid fa-info text-white"} ></i>
                  <p className={splitLocation[1] == "/ApiClientInformation" ? "sidebarcolor" : " text-white"}>Api Create Information</p>
                </NavLink>
              </li>}

              {roleId == "4" || adminId == "106" ? "" : <li
                className={splitLocation[1] == "/helpadmin" ? "active" : ""}
                onClick={(e) => AddUrl(e.target)}
              >
                <NavLink to="/admin/helpadmin">
                  <i className={splitLocation[1] == "/helpadmin" ? "fa fa-handshake" : "fa fa-handshake  text-white"}></i>
                  <p className={splitLocation[1] == "/helpadmin" ? "sidebarcolor " : " text-white"}>Help Center</p>
                </NavLink>
              </li>}


              {/* Demo */}

              {/* <li
                className={splitLocation[1] == "/democlients" ? "active" : ""}
                onClick={(e) => AddUrl(e.target)}
              >
                <NavLink to="/admin/democlients">
                  <i className={splitLocation[1] == "/democlients" ? "fa fa-signal  sidebarcolor" : "fa fa-signal text-white"}></i>
                  <p className={splitLocation[1] == "/democlients" ? "sidebarcolor " : "text-white"}>Demo Clients</p>
                </NavLink>
              </li> */}

            </ul>
            <div style={{ height: '100px' }}></div>
          </PerfectScrollbar>
        </div>
      </div>
      <div className="sidebar openbar" id="mySidebar" data-color="orange" style={{ height: '100vh' }}>
        <div className="logo">

          {roleId == "4" ?
            (<NavLink to="/admin/signals">
              <img
                className="simple-text logo-normal fix-image"
                src='/images/ttt.png'
              />

            </NavLink>) :
            (<NavLink to="/admin/dashboard">
              <img
                className="logo-normal fix-image"
                src='/images/ttt.png'
              />
              {/* <img
                className="simple-text logo-normal fix-image"
                src={`/images/${companyDetails && companyDetails[0].image}`}
              />*/}
            </NavLink>)
          }

          {/* || locationname == '180.149.241.128:3000' */}

          {(locationname == 'test.smartalgo.in' || locationname == 'client.quickalgoplus.in' || locationname == 'software.chartology.in' || locationname == 'software.algoitech.com' || locationname == 'software.adonomist.com' || locationname == '180.149.241.128:3000') &&

            <div>
              <span className="ms-5 text-light">{companyDetails && companyDetails[0].withbroker}</span><br />
              <span className="ms-5 text-light">{companyDetails && companyDetails[0].versions}</span>
            </div>}

        </div>
        <div className="sidebar-wrapper" id="sidebar-wrapper" >
          <PerfectScrollbar component="div">
            <ul className="nav">
              {roleId == "4" ? (
                ""
              ) : (
                <li className={splitLocation1[1] == "/dashboard" ? "active" : ""}
                  name="dashboard"
                  onClick={(e) => AddUrl(e.target)}
                >
                  <NavLink to="/admin/dashboard">
                    <i className={splitLocation1[1] == "/dashboard" ? "fa fa-home sidebarcolor" : "fa fa-home text-white"}></i>
                    <p className={splitLocation1[1] == "/dashboard" ? "sidebarcolor " : "text-white"}>Dashboard</p>
                  </NavLink>
                </li>
              )}
              {roleId == "4" || adminId == "106" ? (
                ""
              ) : (
                <li
                  className={splitLocation[1] == "/subadmin" ? "active" : ""}
                  name="subadmin"
                  onClick={(e) => AddUrl(e.target)}
                >
                  <NavLink to="/admin/subadmin">
                    <i className={splitLocation[1] == "/subadmin" ? "fa fa-user  sidebarcolor" : "fa fa-user text-white"}></i>
                    <p className={splitLocation[1] == "/subadmin" ? "sidebarcolor " : "text-white"}>Sub-Admin</p>
                  </NavLink>
                </li>
              )}
              {`${Config.panel_name}` == 'startalgo' || `${Config.panel_name}` == 'smartalgo' ?

                roleId == "4" ? (
                  ""
                ) : (
                  <li
                    className={splitLocation[1] == "/subadminclients" ? "active" : ""}
                    name="subadmin"
                    onClick={(e) => AddUrl(e.target)}
                  >
                    <NavLink to="/admin/subadminclients">
                      <i className={splitLocation[1] == "/subadminclients" ? "fa fa-user  sidebarcolor" : "fa fa-user text-white"}></i>
                      <p className={splitLocation[1] == "/subadminclients" ? "sidebarcolor " : "text-white"}>Sub-Admin Client</p>
                    </NavLink>
                  </li>
                ) : ""}

              {roleId == "4" ? (
                <li
                  className={splitLocation[1] == "/subadmin/subadmindashboard" ? "active" : ""}
                  onClick={(e) => AddUrl(e.target)}
                >
                  <NavLink to="/subadmin/subadmindashboard">
                    <i className="fa fa-home sidebarcolor"></i>
                    <p className={splitLocation[1] == "/subadmin/subadmindashboard" ? "sidebarcolor " : "text-white"}>  Dasboard</p>
                  </NavLink>
                </li>
              ) : ""}



              {adminId == "106" ? "" :
                <li
                  className={splitLocation[1] == "/signals" ? "active" : ""}
                  onClick={(e) => AddUrl(e.target)}
                >
                  <NavLink to="/admin/signals">
                    <i className={splitLocation[1] == "/signals" ? "fa fa-signal  sidebarcolor" : "fa fa-signal text-white"}></i>
                    <p className={splitLocation[1] == "/signals" ? "sidebarcolor " : "text-white"}>Signals</p>
                  </NavLink>
                </li>}


              {/*  For Only Sub- ADmin */}

              { roleId == "4" ?
                <li
                  className={splitLocation1[0] == "/subadmin/clientlist" ? "active" : ""}
                  onClick={(e) => AddUrl(e.target)}
                >
                  <NavLink to="/subadmin/clientlist">
                    <i class={splitLocation1[0] == "/subadmin/clientlist" ? "  fa-solid fa-list-check  sidebarcolor" : "  fa-solid fa-list-check text-white"}></i>
                    <p className={splitLocation1[0] == "/subadmin/clientlist" ? "sidebarcolor " : "text-white"}>Client List</p>
                  </NavLink>
                </li>
                : ""}


                

              {roleId == "4" || adminId == "106" ? "" :
                <li
                  className={splitLocation[1] == "/system" ? "active" : "text-"}
                  onClick={(e) => AddUrl(e.target)}
                >
                  <NavLink to="/admin/system">
                    <i className={splitLocation[1] == "/system" ? "fa fa-asterisk  sidebarcolor" : "fa fa-asterisk text-white"} ></i>
                    <p className={splitLocation[1] == "/system" ? "sidebarcolor " : "text-white"}>System</p>
                  </NavLink>
                </li>}


              {roleId == "4" || adminId == "106" ? (
                ""
              ) : (
                <li
                  className={splitLocation[1] == "/strategy" ? "active" : ""}
                  onClick={(e) => AddUrl(e.target)}
                >
                  <NavLink to="/admin/strategy">
                    <i className={splitLocation[1] == "/strategy" ? "fas fa-chess-board  sidebarcolor" : "fas fa-chess-board text-white"} ></i>
                    <p className={splitLocation[1] == "/strategy" ? "sidebarcolor " : "text-white"}>Strategy</p>
                  </NavLink>
                </li>
              )}
              {roleId == "4" || adminId == "106" ? "" :
                <li
                  className={splitLocation[1] == "/services" ? "active" : ""}
                  onClick={(e) => AddUrl(e.target)}
                >
                  <NavLink to="/admin/services">
                    <i className={splitLocation[1] == "/services" ? "fa fa-wrench  sidebarcolor" : "fa fa-wrench text-white"} ></i>
                    <p className={splitLocation[1] == "/services" ? "sidebarcolor " : "text-white"}>Services</p>
                  </NavLink>
                </li>}
              {/* {roleId == "4" ? (
                ""
              ) : (
                <li
                  className={
                    splitLocation[1] == "/group-services" ? "active" : ""
                  }
                  onClick={(e) => AddUrl(e.target)}
                >
                  <NavLink to="/admin/group-services">
                    <i className={splitLocation[1] == "/group-services" ? "fa fa-cogs  sidebarcolor" : "fa fa-cogs text-white"} ></i>
                    <p className={splitLocation[1] == "/group-services" ? "sidebarcolor " : "text-white"}>Group Services</p>
                  </NavLink>
                </li>
              )} */}
              {/* {roleId == "4" || adminId == "106" ? (
                ""
              ) : (
                <li
                  className={splitLocation[1] == "/reports" ? "active" : ""}
                  onClick={(e) => AddUrl(e.target)}
                >
                  <NavLink to="/admin/reports">
                    <i className={splitLocation[1] == "/reports" ? "fa fa-clipboard  sidebarcolor" : "fa fa-clipboard text-white"} ></i>
                    <p className={splitLocation[1] == "/reports" ? "sidebarcolor " : "text-white"}>Reports</p>
                  </NavLink>
                </li>
              )} */}

              <li
                className={splitLocation[1] == "/admin/client/add-client" ? "active" : ""}
                onClick={(e) => AddUrl(e.target)}
              >
                <NavLink to="/admin/client/add-client">
                  <i className={splitLocation[1] == "/admin/client/add-client" ? "fa fa-user-plus  sidebarcolor" : "fa fa-user-plus text-white"}></i>
                  <p className={splitLocation[1] == "/admin/client/add-client" ? "sidebarcolor " : "text-white"}>Add Clients</p>
                </NavLink>
              </li>

              {roleId == "4" ? (
                ""
              ) : (
                <li
                  className={splitLocation[1] == "/clients" ? "active" : ""}
                  onClick={(e) => AddUrl(e.target)}
                >
                  <NavLink to="/admin/clients">
                    <i className={splitLocation[1] == "/clients" ? "fa fa-user  sidebarcolor" : "fa fa-user text-white"} ></i>
                    <p className={splitLocation[1] == "/clients" ? "sidebarcolor " : "text-white"}>Clients</p>
                  </NavLink>
                </li>
              )}

              {adminId == "106" ? "" :

                roleId != "4" ?
                  roleId == "4" || adminId == "106" ? "" :
                    <li
                      className={splitLocation[1] == "/tradehistory" ? "active" : ""}
                      onClick={(e) => AddUrl(e.target)}
                    >
                      <NavLink to="/admin/tradehistoryL">
                        <i className={splitLocation[1] == "/tradehistory" ? "fa fa-history  sidebarcolor" : "fa fa-history text-white"} ></i>
                        <p className={splitLocation[1] == "/tradehistory" ? "sidebarcolor " : "text-white"}>Trade History</p>
                      </NavLink>
                    </li>

                  :

                  <li
                    className={splitLocation[1] == "/tradehistory" ? "active" : ""}
                    onClick={(e) => AddUrl(e.target)}
                  >
                    <NavLink to="/admin/tradehistory">
                      <i className={splitLocation[1] == "/tradehistory" ? "fa fa-history  sidebarcolor" : "fa fa-history text-white"} ></i>
                      <p className={splitLocation[1] == "/tradehistory" ? "sidebarcolor " : "text-white"}>Trade History</p>
                    </NavLink>
                  </li>

                // <li
                //   className={splitLocation[1] == "/tradehistory" ? "active" : ""}
                //   onClick={(e) => AddUrl(e.target)}
                // >
                //   <NavLink to="/admin/tradehistory">
                //     <i className={splitLocation[1] == "/tradehistory" ? "fa fa-history  sidebarcolor" : "fa fa-history text-white"} ></i>
                //     <p className={splitLocation[1] == "/tradehistory" ? "sidebarcolor " : "text-white"}>Trade History</p>
                //   </NavLink>
                // </li>

              }

              {/* {roleId == "4" ? (
                <li
                  className={splitLocation[1] == "/clientlist" ? "active" : ""}
                  onClick={(e) => AddUrl(e.target)}
                >
                  <NavLink to="/subadmin/clientlist">
                    <i class="fa-solid fa-list-check"></i>
                    <p>Client List</p>
                  </NavLink>
                </li>
              ) : ( */}

              {/* (
                 <li
                   className={
                     splitLocation1[0] == "/subadmin/subtradingstatus" ? "active" : " "
                   }
                   onClick={(e) => AddUrl(e.target)}
                 >
                   <NavLink to="/subadmin/subtradingstatus">
                     <i class={splitLocation1[0] == "/subadmin/subtradingstatus" ? "fa-solid fa-stairs  sidebarcolor" : "fa-solid fa-stairs text-white"} ></i>
                     <p className={splitLocation1[0] == "/subadmin/subtradingstatus" ? "sidebarcolor" : "text-white"}>Sub Trading Status</p>
                   </NavLink>
                 </li>
               )   */}
              {/* {roleId == "4" ? ""
                : (
                  <li
                    className={
                      splitLocation[1] == "/tradingstatusadmin" ? "active" : ""
                    }
                    onClick={(e) => AddUrl(e.target)}
                  >
                    <NavLink to="/admin/tradingstatusadmin">
                      <i class={splitLocation[1] == "/tradingstatusadmin" ? "fa-solid fa-stairs  sidebarcolor" : "fa-solid fa-stairs text-white"} ></i>
                      <p className={splitLocation[1] == "/tradingstatusadmin" ? "sidebarcolor" : "text-white"}>Trading Status</p>
                    </NavLink>
                  </li>
                )} */}

              {roleId == "4" || adminId == "106" ? (
                ""
              ) : (
                <li
                  className={
                    splitLocation[1] == "/message-broadcast" ? "active" : ""
                  }
                  onClick={(e) => AddUrl(e.target)}
                >
                  <NavLink to="/admin/message-broadcast">
                    <i className={splitLocation[1] == "/message-broadcast" ? "fa fa-bullhorn  sidebarcolor" : "fa fa-bullhorn text-white"}></i>
                    <p className={splitLocation[1] == "/message-broadcast" ? "sidebarcolor" : "text-white"}>Message Broadcast</p>
                  </NavLink>
                </li>
              )}

              {/* {roleId == "4" || adminId == "106" ? (
                ""
              ) : (
                <li
                  className={
                    splitLocation[1] == "/transactionlicence" ? "active" : ""
                  }
                  onClick={(e) => AddUrl(e.target)}
                >
                  <NavLink to="/admin/transactionlicence">
                    <i className={splitLocation[1] == "/transactionlicence" ? "fa fa-exchange   sidebarcolor" : "fa fa-exchange text-white"} ></i>
                    <p className={splitLocation[1] == "/transactionlicence" ? "sidebarcolor" : " text-white"}>Transaction Licence</p>
                  </NavLink>
                </li>
              )} */}



              {roleId == "4" ? "" : <li
                className={
                  splitLocation[1] == "/ApiClientInformation" ? "active" : ""
                }
                onClick={(e) => AddUrl(e.target)}
              >
                <NavLink to="/admin/ApiClientInformation">
                  <i class={splitLocation[1] == "/ApiClientInformation" ? "fa-solid fa-info  sidebarcolor" : "fa-solid fa-info text-white"} ></i>
                  <p className={splitLocation[1] == "/ApiClientInformation" ? "sidebarcolor" : " text-white"}>Api Create Information</p>
                </NavLink>
              </li>}

              {roleId == "4" || adminId == "106" ? "" : <li
                className={splitLocation[1] == "/helpadmin" ? "active" : ""}
                onClick={(e) => AddUrl(e.target)}
              >
                <NavLink to="/admin/helpadmin">
                  <i className={splitLocation[1] == "/helpadmin" ? "fa fa-handshake" : "fa fa-handshake  text-white"}></i>
                  <p className={splitLocation[1] == "/helpadmin" ? "sidebarcolor " : " text-white"}>Help Center</p>
                </NavLink>
              </li>}



           
              { (roleId == '1' || roleId == '10') && email != 'compliance@startalgo.com' ? 
              <li
              className={splitLocation[1] == "/plan" ? "active" : ""}
              onClick={(e) => AddUrl(e.target)}
            >
              <NavLink to="/admin/plan">
                <i className={splitLocation[1] == "/plan" ? " fa fa-money-bill" : " fa fa-money-bill  text-white"}></i>
                <p className={splitLocation[1] == "/plan" ? "sidebarcolor " : " text-white"}>Plans</p>
              </NavLink>
            </li>
              : ""}

{ roleId == '1'  && email != 'compliance@startalgo.com' ? 
              <li
              className={splitLocation[1] == "/brokerapiupdate" ? "active" : ""}
              onClick={(e) => AddUrl(e.target)}
            >
              <NavLink to="/admin/brokerapiupdate">
                <i className={splitLocation[1] == "/brokerapiupdate" ? " fa fa-money-bill" : " fa fa-money-bill  text-white"}></i>
                <p className={splitLocation[1] == "/brokerapiupdate" ? "sidebarcolor " : " text-white"}>Update Broker Api Keys</p>
              </NavLink>
            </li>
              : ""}
   
              {/* Demo */}

              {/* <li
                className={splitLocation[1] == "/democlients" ? "active" : ""}
                onClick={(e) => AddUrl(e.target)}
              >
                <NavLink to="/admin/democlients">
                  <i className={splitLocation[1] == "/democlients" ? "fa fa-signal  sidebarcolor" : "fa fa-signal text-white"}></i>
                  <p className={splitLocation[1] == "/democlients" ? "sidebarcolor " : "text-white"}>Demo Clients</p>
                </NavLink>
              </li> */}

            </ul>
            <div style={{ height: '100px' }}></div>
          </PerfectScrollbar>
        </div>
      </div>
    </>
  );

  // else if(roleId == 4){
  //   return <>
  //   <div className="sidebar" data-color="orange">
  //         <div className="logo">
  //           <NavLink to="/admin/dashboard">
  //             <img
  //               className="simple-text logo-normal"
  //               src={`/images/${companyDetails && companyDetails[0].image}`}
  //             />
  //           </NavLink>
  //         </div>
  //         <div className="sidebar-wrapper" id="sidebar-wrapper">
  //           <PerfectScrollbar component="div">
  //             <ul className="nav">
  //               <li
  //                 className={splitLocation[1] == "/signals" ? "active" : ""}
  //                 onClick={(e) => AddUrl(e.target)}
  //               >
  //                 <NavLink to="/admin/signals">
  //                   <i className="fa fa-signal"></i>
  //                   <p>Signals</p>
  //                 </NavLink>
  //               </li>

  //             </ul>
  //           </PerfectScrollbar>
  //         </div>
  //       </div>
  //    </>
  // }
}

export default Sidebar;

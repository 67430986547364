import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  NavLink,
  useLocation,
} from "react-router-dom";
import axios from "axios";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import * as Config from '../common/Config';

function Sidebar() {
  const [clientCompanyDetails, setClientCompanyDetails] = useState("");
  // const [defaultName, setDefaultName] = useState();
  const location = useLocation();
  const client_idparam = useParams()
  const fromadmin = localStorage.getItem('from_admin');
  const roleId = localStorage.getItem('roleId');
  // console.log("consoleget", fromadmin);
  const user_id = localStorage.getItem("client_id");
  const client_token = localStorage.getItem('client_token');
  const { pathname } = location;
  const locationname = window.location.host
  // console.log("pathname", locationname);
  const splitLocation = pathname.split("/client");

  const [showTradeHistoryStatus, setShowTradeHistoryStatus] = useState("");
  const [showApiCreateStatus, setShowApiCreateStatus] = useState("");
// console.log("showTradeHistoryStatus",showTradeHistoryStatus);
// console.log("showTradeHistoryStatus",showTradeHistoryStatus);

  // const showTradeHistoryStatus = localStorage.getItem('showtradehistory');
  // const showApiCreateStatus = localStorage.getItem('showapidetails');

  const getClientId = localStorage.getItem('client_id');
  const isAuthenticated = localStorage.getItem('client_token');

  // console.log("clientdata", getClientId);


  // console.log("showApiCreateStatus", showApiCreateStatus);



  useEffect(() => {

    axios({
      method: "post",
      url: `${Config.base_url}smartalgo/client/tradeStatusGet`,
      data: {
        client_id: getClientId,
      },
      headers: {
        'x-access-token': isAuthenticated
      }
    }).then(function (response) {
      // console.log("clientdata", response.data.data);
      if (response) {
        setShowTradeHistoryStatus(response.data.data.show_tradeHistory)
        setShowApiCreateStatus(response.data.data.show_apiDetails)
        // console.log("1",response.data.data.show_tradeHistory);
      }
    });

    axios({
      method: "get",
      url: `${Config.base_url}admin/system_company`,
      data: {},
    }).then(function (response) {
      // console.log("response", response);
      setClientCompanyDetails(response.data.data);
      document.getElementById("title").innerText = response.data.data[0].name;
    });

  }, []);

  const AddUrl = (name) => {
    return (document.getElementById("title").innerText = name.innerText);
  };


  return (
    <>
      <div className="sidebar small" data-color="orange">
        <div className="logo">
          <NavLink to="/">
          <img
            className="simple-text logo-normal  w-100 pt-0"
            src='/images/ttt.png'
          />
          {/*  <img
              className="simple-text logo-normal"
              src={`/images/${clientCompanyDetails && clientCompanyDetails[0].image
                }`}
            /> */}
          </NavLink>

          {(locationname == 'test.smartalgo.in' || locationname == 'client.quickalgoplus.in' || locationname == 'software.chartology.in' || locationname == 'software.algoitech.com' || locationname == 'software.adonomist.com' || locationname == '180.149.241.128:3000') &&

            <div>
              <span className="ms-5 text-light">{clientCompanyDetails && clientCompanyDetails[0].withbroker}</span><br />
              <span className="ms-5 text-light">{clientCompanyDetails && clientCompanyDetails[0].versions}</span>
            </div>}

        </div>


        <div className="sidebar-wrapper" id="sidebar-wrapper">
          <PerfectScrollbar component="div">
            <ul className="nav">
              <li
                className={splitLocation[0] == "/" ? "active" : ""}
                onClick={(e) => AddUrl(e.target)}
              >
                <NavLink to="/">
                  <i className={splitLocation[0] == "/" ? "fa fa-home sidebarcolor" : "fa fa-home text-white"}></i>
                  <p className={splitLocation[0] == "/" ? "sidebarcolor" : " text-white"}>Dashboard</p>
                </NavLink>
              </li>
              <li
                className={splitLocation[0] == "/signals" ? "active" : ""}
                onClick={(e) => AddUrl(e.target)}
              >
                <NavLink to="/signals">
                  <i className={splitLocation[0] == "/signals" ? "fa fa-signal sidebarcolor" : "fa fa-signal  text-white"}></i>
                  <p className={splitLocation[0] == "/signals" ? "sidebarcolor" : " text-white"}>Signals</p>
                </NavLink>
              </li>

              {showTradeHistoryStatus == 1 ?
                <li
                  className={splitLocation[0] == "/tradehistory" ? "active" : ""}
                  onClick={(e) => AddUrl(e.target)}
                >
                  <NavLink to="/tradehistory">
                    <i className={splitLocation[0] == "/tradehistory" ? "fa fa-history sidebarcolor" : "fa fa-history  text-white"}></i>
                    <p className={splitLocation[0] == "/tradehistory" ? "sidebarcolor" : " text-white"}>Trade History</p>
                  </NavLink>
                </li>
                : ""}

              <li
                className={
                  splitLocation[1] == "/tradingstatus" ? "active" : ""
                }
                onClick={(e) => AddUrl(e.target)}
              >
                <NavLink to="/client/tradingstatus">
                  <i className={splitLocation[1] == "/tradingstatus" ? "fa-solid fa-money-bill-trend-up sidebarcolor" : "fa-solid fa-money-bill-trend-up text-white"}></i>
                  <p className={splitLocation[1] == "/tradingstatus" ? "sidebarcolor " : " text-white"}>Trading Status</p>
                </NavLink>
              </li>
              <li
                className={
                  splitLocation[1] == "/brokerresponse" ? "active" : ""
                }
                onClick={(e) => AddUrl(e.target)}
              >
                <NavLink to="/client/brokerresponse">
                  <i className={splitLocation[1] == "/brokerresponse" ? "fa-solid fa-reply-all" : "fa-solid fa-reply-all  text-white"}></i>
                  <p className={splitLocation[1] == "/brokerresponse" ? "sidebarcolor" : " text-white"}>Broker Response</p>
                </NavLink>
              </li>

              {showApiCreateStatus == 1 ?
                <li
                  className={
                    splitLocation[1] == "/apicreateinformation" ? "active" : ""
                  }
                  onClick={(e) => AddUrl(e.target)}
                >
                  <NavLink to="/client/apicreateinformation">
                    <i className={splitLocation[1] == "/apicreateinformation" ? "fa fa-database" : "fa fa-database  text-white"}></i>
                    <p className={splitLocation[1] == "/apicreateinformation" ? "sidebarcolor " : " text-white"}>API Create Information</p>
                  </NavLink>
                </li>
                : ""}


              <li
                className={splitLocation[1] == "/help" ? "active" : ""}
                onClick={(e) => AddUrl(e.target)}
              >
                <NavLink to="/client/help">
                  <i className={splitLocation[1] == "/help" ? "fa fa-handshake" : "fa fa-handshake  text-white"}></i>
                  <p className={splitLocation[1] == "/help" ? "sidebarcolor " : " text-white"}>Help Center</p>
                </NavLink>
              </li>

              <li
                className={splitLocation[1] == "/strategydescription" ? "active" : ""}
                onClick={(e) => AddUrl(e.target)}
              >
                <NavLink to="/client/strategydescription">
                  <i className={splitLocation[1] == "/strategydescription" ? "fa fa-file-text-o" : "fa fa-file-text-o  text-white"}></i>
                  <p className={splitLocation[1] == "/strategydescription" ? "sidebarcolor " : " text-white"}>Strategy Description</p>
                </NavLink>
              </li>

              {(fromadmin != null && (user_id == 13 || user_id == 14 || user_id == 92) && locationname == 'client.algomaster.in' || fromadmin != null && user_id == 53 && locationname == 'software.algoking.in' || fromadmin != null && user_id == 2 && locationname == 'software.goalgos.com') &&

                <li
                  className={splitLocation[1] == "/tradehistoryd" ? "active" : ""}
                  onClick={(e) => AddUrl(e.target)}
                >
                  <NavLink to="/client/tradehistoryd">
                    <i className={splitLocation[1] == "/tradehistoryd" ? "fa fa-history" : "fa fa-history  text-white"}></i>
                    <p className={splitLocation[1] == "/tradehistoryd" ? "sidebarcolor " : " text-white"}>Trade History D</p>
                  </NavLink>
                </li>}


            </ul>
          </PerfectScrollbar>
        </div>
      </div>
      <div className="sidebar" data-color="orange">
        <div className="logo">
          <NavLink to="/">
          <img
            className="simple-text logo-normal  w-100 pt-0"
            src='/images/ttt.png'
          />
          {/*  <img
              className="simple-text logo-normal"
              src={`/images/${clientCompanyDetails && clientCompanyDetails[0].image
                }`}
            /> */}
          </NavLink>

          {(locationname == 'test.smartalgo.in' || locationname == 'client.quickalgoplus.in' || locationname == 'software.chartology.in' || locationname == 'software.algoitech.com' || locationname == 'software.adonomist.com' || locationname == '180.149.241.128:3000') &&

            <div>
              <span className="ms-5 text-light">{clientCompanyDetails && clientCompanyDetails[0].withbroker}</span><br />
              <span className="ms-5 text-light">{clientCompanyDetails && clientCompanyDetails[0].versions}</span>
            </div>}

        </div>


        <div className="sidebar-wrapper" id="sidebar-wrapper">
          <PerfectScrollbar component="div">
            <ul className="nav">
              <li
                className={splitLocation[0] == "/" ? "active" : ""}
                onClick={(e) => AddUrl(e.target)}
              >
                <NavLink to="/">
                  <i className={splitLocation[0] == "/" ? "fa fa-home sidebarcolor" : "fa fa-home text-white"}></i>
                  <p className={splitLocation[0] == "/" ? "sidebarcolor" : " text-white"}>Dashboard</p>
                </NavLink>
              </li>
              <li
                className={splitLocation[0] == "/signals" ? "active" : ""}
                onClick={(e) => AddUrl(e.target)}
              >
                <NavLink to="/signals">
                  <i className={splitLocation[0] == "/signals" ? "fa fa-signal sidebarcolor" : "fa fa-signal  text-white"}></i>
                  <p className={splitLocation[0] == "/signals" ? "sidebarcolor" : " text-white"}>Signals</p>
                </NavLink>
              </li>

              {showTradeHistoryStatus == 1 ?
                <li
                  className={splitLocation[0] == "/tradehistory" ? "active" : ""}
                  onClick={(e) => AddUrl(e.target)}
                >
                  <NavLink to="/tradehistory">
                    <i className={splitLocation[0] == "/tradehistory" ? "fa fa-history sidebarcolor" : "fa fa-history  text-white"}></i>
                    <p className={splitLocation[0] == "/tradehistory" ? "sidebarcolor" : " text-white"}>Trade History</p>
                  </NavLink>
                </li>
                : ""}

              <li
                className={
                  splitLocation[1] == "/tradingstatus" ? "active" : ""
                }
                onClick={(e) => AddUrl(e.target)}
              >
                <NavLink to="/client/tradingstatus">
                  <i className={splitLocation[1] == "/tradingstatus" ? "fa-solid fa-money-bill-trend-up sidebarcolor" : "fa-solid fa-money-bill-trend-up text-white"}></i>
                  <p className={splitLocation[1] == "/tradingstatus" ? "sidebarcolor " : " text-white"}>Trading Status</p>
                </NavLink>
              </li>
              <li
                className={
                  splitLocation[1] == "/brokerresponse" ? "active" : ""
                }
                onClick={(e) => AddUrl(e.target)}
              >
                <NavLink to="/client/brokerresponse">
                  <i className={splitLocation[1] == "/brokerresponse" ? "fa-solid fa-reply-all" : "fa-solid fa-reply-all  text-white"}></i>
                  <p className={splitLocation[1] == "/brokerresponse" ? "sidebarcolor" : " text-white"}>Broker Response</p>
                </NavLink>
              </li>

              {showApiCreateStatus == 1 ?
                <li
                  className={
                    splitLocation[1] == "/apicreateinformation" ? "active" : ""
                  }
                  onClick={(e) => AddUrl(e.target)}
                >
                  <NavLink to="/client/apicreateinformation">
                    <i className={splitLocation[1] == "/apicreateinformation" ? "fa fa-database" : "fa fa-database  text-white"}></i>
                    <p className={splitLocation[1] == "/apicreateinformation" ? "sidebarcolor " : " text-white"}>API Create Information</p>
                  </NavLink>
                </li>
                : ""}


              <li
                className={splitLocation[1] == "/help" ? "active" : ""}
                onClick={(e) => AddUrl(e.target)}
              >
                <NavLink to="/client/help">
                  <i className={splitLocation[1] == "/help" ? "fa fa-handshake" : "fa fa-handshake  text-white"}></i>
                  <p className={splitLocation[1] == "/help" ? "sidebarcolor " : " text-white"}>Help Center</p>
                </NavLink>
              </li>

              <li
                className={splitLocation[1] == "/strategydescription" ? "active" : ""}
                onClick={(e) => AddUrl(e.target)}
              >
                <NavLink to="/client/strategydescription">
                  <i className={splitLocation[1] == "/strategydescription" ? "fa fa-file-text-o" : "fa fa-file-text-o  text-white"}></i>
                  <p className={splitLocation[1] == "/strategydescription" ? "sidebarcolor " : " text-white"}>Strategy Description</p>
                </NavLink>
              </li>

              {(fromadmin != null && (user_id == 13 || user_id == 14 || user_id == 92) && locationname == 'client.algomaster.in' || fromadmin != null && user_id == 53 && locationname == 'software.algoking.in' || fromadmin != null && user_id == 2 && locationname == 'software.goalgos.com') &&

                <li
                  className={splitLocation[1] == "/tradehistoryd" ? "active" : ""}
                  onClick={(e) => AddUrl(e.target)}
                >
                  <NavLink to="/client/tradehistoryd">
                    <i className={splitLocation[1] == "/tradehistoryd" ? "fa fa-history" : "fa fa-history  text-white"}></i>
                    <p className={splitLocation[1] == "/tradehistoryd" ? "sidebarcolor " : " text-white"}>Trade History D</p>
                  </NavLink>
                </li>}


            </ul>
          </PerfectScrollbar>
        </div>
      </div>
    </>
  );
}

export default Sidebar;



// (fromadmin != null && user_id == 53 && locationname == 'software.algoking.in') &&
//                 (fromadmin != null && user_id == 2 && locationname == 'software.goalgos.com') &&

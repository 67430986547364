var dateTime = require('node-datetime');


export const dateFormate = (date) =>{
    var dt = dateTime.create(date);
    var ccdate = dt.format('Y-m-d H:M:S');
    return ccdate
} 
export const dateFormate1 = (date) =>{
    var dt = dateTime.create(date);
    var ccdate = dt.format('d-m-Y H:M:S');
    return ccdate
} 

let a = window.location.hostname
const firstDotIndex = a.indexOf(".");
const firstPart = a.slice(0, firstDotIndex);
const remainingPart = a.slice(firstDotIndex + 1)
let panelan = remainingPart.split('.')[0]
const panelname = panelan.replace("-", "")


export const base_url = `${window.location.origin}/backend/`
export const broker_url = `${window.location.origin}/backend/`
export const broker_redirect_url = `${window.location.origin}/backend/`
export const react_domain = `${window.location.origin}`;
export const panel_name = panelname;
export const broker_signal_url = `${window.location.origin}/signal/broker-signals`;



// Testing server connections

// export const base_url = "http://180.149.241.128:3001/";
// export const broker_url = "http://180.149.241.128:3001/";
// export const broker_redirect_url = "http://180.149.241.128:3001/";
// export const react_domain = "http://180.149.241.128:3000/";
// export const panel_name = "smartalgo";

//Live Connections Smart algo

// export const base_url = "https://api.smartalgo.in:3001/";
// export const broker_url = "https://api.smartalgo.in:3001/";
// export const broker_redirect_url = "https://api.smartalgo.in:3001/";
// export const react_domain = "https://test.smartalgo.in/";
// export const panel_name = "smartalgo";

// Test Connections startalgo

// export const base_url = "https://client.startalgo.com:3013/";
// export const broker_url = "https://client.startalgo.com:3013/";
// export const broker_redirect_url = "https://client.startalgo.com:3013/";
// export const react_domain = "https://client.startalgo.com/";
// export const panel_name = "startalgo";

// Live Connections startalgo

// export const base_url = "https://trade.startalgo.com:3095/";
// export const broker_url = "https://trade.startalgo.com:3095/";
// export const broker_redirect_url = "https://trade.startalgo.com:3095/";
// export const react_domain = "https://trade.startalgo.com/";
// export const panel_name = "startalgo";
// export const broker_signal_url = "https://brokersignal.startalgo.com/broker-signals";



// export const base_url = "https://trade.startalgo.com/backend/";
// export const broker_url = "/";
// export const broker_redirect_url = "https://trade.startalgo.com/backend/";
// export const react_domain = "https://trade.startalgo.com/";
// export const panel_name = "startalgo";
// export const broker_signal_url = "https://brokersignal.startalgo.com/broker-signals";
// import React, { useEffect, useMemo, useState } from "react";
// import { BrowserRouter, Routes, Route, Link, useNavigate } from "react-router-dom";
// import axios from "axios";
// import { Form } from "react-bootstrap";
// import { NavLink } from "react-router-dom";
// import * as Config from "../common/Config";
// import AlertToast from "../common/AlertToast";
// import Backdrop from '@mui/material/Backdrop';
// import * as Constant from "../common/ConstantMessage";
// import CircularProgress from '@mui/material/CircularProgress';

// function Dashboard() {
//   let navigate = useNavigate();
//   const [services, setServices] = useState([]);
//   const [strategy, setStrategy] = useState([]);
//   const [showAlert, setShowAlert] = useState(false);
//   const [textAlert, setTextAlert] = useState("");
//   const [alertColor, setAlertColor] = useState("");
//   const [refreshscreen, setRefreshscreen] = useState(true);
//   const [orderTypeValue, setOrderTypeValue] = useState("");
//   const [rerender, setRerender] = useState()
//   const [loader, setLoader] = useState(false);

//   const client_token = localStorage.getItem('client_token');
//   const fromadmin = localStorage.getItem('from_admin');
//   const fromsubadmin = localStorage.getItem('from_subadmin');

//   var user_id = localStorage.getItem("client_id");
//   var data_array = [];
//   var updatedata_array = [];

//   useEffect(() => {
//     setLoader(true)
//     axios({
//       url: `${Config.base_url}client/services`,
//       method: "post",
//       data: {
//         client_id: user_id,
//       },
//       headers: {
//         'x-access-token': client_token
//       }
//     }).then((res) => {

//       setStrategy(res.data.strategy);
//       setServices(res.data.services);

//       setLoader(false)
//     });
//   }, [rerender]);

//   const onAlertClose = (e) => {
//     setShowAlert(false);
//   };

//   // const onShowClick = (id) => {
//   //   if (window.confirm(Constant.DELETE_GROUP_CONFIRM_MSG)) {
//   //     axios({
//   //       method: "post",
//   //       url: `${Config.base_url}smartalgo/group-services/delete`,
//   //       data: { id: id },
//   //     }).then(function (response) {
//   //       setRefreshscreen(!refreshscreen);
//   //     });
//   //   }
//   // };

//   const setAllToSetStrategy = (e) => {
//     if (e.target.value === "all") {
//       setRerender(!rerender)
//     }
//     setServices(
//       services.map((item, index) => {
//         item.strategy = e.target.value
//         return item
//       }
//       )
//     )
//   }


//   const serviceChange = (e, id) => {
//      if (e.target.value == "") {

//         setServices(
//           services.map(
//             (item, index) => (item.qty = index == id ? "" : item)
//           )
//         );
//         return
//       } 
//     if (e.target.name == "qty") {

//       //console.log('qty',typeof e.target.value);
//       const re = /^[0-9\b]+$/;

//       // else {
//       setServices(
//         services.filter(
//           (item, index) => {
//             if (index == id) {
//               item.qty = e.target.value
//             } else {
//               item.qty = item.qty
//             }
//             return item
//           }
//         )
//       );
//       // }
//     }

//     if (e.target.name == "cstrategy") {
//       setServices(
//         services.filter(
//           (item, index) =>
//             (item.strategy = index == id ? e.target.value : item.strategy)
//         )
//       );
//     }

//     if (e.target.name == "order_type") {

//       setServices(
//         services.filter(
//           (item, index) =>
//             (item.order_type = index == id ? e.target.value : item.order_type)
//         )
//       );
//     }

//     if (e.target.name == "product_type") {
//       setOrderTypeValue(e.target.value);
//       setServices(
//         services.filter(
//           (item, index) =>
//           (item.product_type =
//             index == id ? e.target.value : item.product_type)
//         )
//       );
//     }

//     if (e.target.name == "trading") {
//       setServices(
//         services.filter((item, index) => {
//           if (index == id) {
//             item.trading = item.trading == 0 ? 1 : 0;
//           }

//           return item;
//         })
//       );
//     }

//   };

//   const handleSubmit = () => {
//     const ServiceArr = services.map((item) => {

//       // console.log('item.qty',item.qty);
//       // var item_qty= parseInt(item.qty);

//       if (item.qty == 0 || item.qty == "" || item.qty == null) {
//         item.qty = 1;
//       }
//       if (item.strategy == 'null' || strategy.length == 1) {
//         item.strategy = strategy[0].strategy

//       } else if (strategy.length > 0) {
//         return item
//       }
//       return item
//     })

//     axios({
//       method: "post",
//       url: `${Config.base_url}client/updateservices`,
//       data: {
//         client_signal: ServiceArr,
//         client_id: user_id,
//       },
//       headers: {
//         'x-access-token': client_token
//       }
//     }).then(function (response) {
//       if (response) {
//         setShowAlert(true);
//         setAlertColor("success");
//         setTextAlert(Constant.UPDATED_SUCCESS);
//         // navigate('../client/Dashboard.js')
//       }
//     });
//   };
//   // console.log("ssssssss",services);


//   return (
//     <>
//       <Backdrop
//         sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1 }}
//         open={loader}
//       // onClick={handleClose}
//       >
//         <CircularProgress color="inherit" />
//       </Backdrop>
//       <div className="content">
//         <div className="row">
//           <div className="col-md-12">
//             <div className="card">
//               <div className="card-header">
//                 <h4 className="card-title font">Dashboard</h4>
//               </div>
//               <div className="card-body">
//                 {/* <div className="row">
//                   <div className="col-md-4 ">
//                     <b>Set All Strategy</b>
//                     <select
//                       name="cstrategy"
//                       // value={sig.strategy}
//                       onChange={(e) => {
//                         setAllToSetStrategy(e);
//                       }}
//                       className="form-control spacing"
//                       style={{ width: "100px" }}
//                     >
//                       <option value="all">All</option>
//                       {strategy && strategy.map((star, i) => (
//                         <option value={star.strategy} key={i}>{star.strategy}</option>
//                       ))}
//                     </select>
//                   </div>
//                 </div> */}

//                 <div className="table-responsive " >
//                   <table className="table tbl-tradehostory">
//                     <thead className="tablecolor">
//                       <tr className="fontbold">
//                         <th className="fontbold">No.</th>
//                         <th className="fontbold">Symbol</th>
//                         <th>Quantity</th>
//                         <th>Segment</th>
//                         <th>Strategy</th>
//                         <th>Order Type</th>
//                         <th>Product Type</th>
//                         <th>Trading</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {services && services.map((sig, index) => (
//                         <tr key={index}>
//                           <td>{index + 1}</td>
//                           <td>{sig.ser_name}</td>
//                           <td>
//                             <input
//                               name="qty"
//                               type="number" 
//                               // pattern="^[0-9\b]+$"
//                               onChange={(e) => {
//                                 serviceChange(e, index);
//                               }}
//                               style={{ width: "150px" }}
//                               value={sig.qty}
//                               className="form-control"
//                             // min="1"                           
//                             />
//                           </td>
//                           <td>{sig.cat_name}</td>
//                           <td>
//                             <select
//                               name="cstrategy"
//                               value={sig.strategy}
//                               onChange={(e) => {
//                                 serviceChange(e, index);
//                               }}
//                               className="form-control"
//                               style={{ width: "130px" }}
//                             >

//                               {strategy && strategy.map((star, i) => (
//                                 <option key={i}>{star.strategy}</option>
//                               ))}
//                             </select>
//                           </td>
//                           <td>
//                             <select
//                               className="order_type form-control"
//                               name="order_type"
//                               value={sig.order_type}
//                               onChange={(e) => {
//                                 serviceChange(e, index);
//                               }}

//                               style={{ width: "100px" }}
//                             >
//                               {sig.product_type == "1" ||
//                                 sig.product_type == "2" ? (
//                                 <>
//                                   <option value="1">MARKET</option>
//                                   {/* <option value="2">LIMIT</option>
//                                   <option value="3">STOPLOSS LIMIT</option>
//                                   <option value="4">STOPLOSS MARKET</option> */}
//                                 </>
//                               ) : sig.product_type == "3" ? (
//                                 <>
//                                   {/* <option value="2">LIMIT</option>
//                                   <option value="3">STOPLOSS LIMIT</option> */}
//                                 </>
//                               ) : sig.product_type == "4" ? (
//                                 <>
//                                   <option value="1">MARKET</option>
//                                   {/* <option value="2">LIMIT</option> */}
//                                 </>
//                               ) : (
//                                 ""
//                               )}
//                             </select>
//                           </td>
//                           <td>
//                             <select
//                               className="product_type form-control"
//                               name="product_type"
//                               value={sig.product_type}
//                               onChange={(e) => {
//                                 serviceChange(e, index);
//                               }}

//                               style={{ width: "100px" }}
//                             >
//                               {/* <option value="1">CNC</option> */}
//                               <option value="2">MIS</option>
//                               {/* <option value="3">BO</option>
//                               <option value="4">CO</option> */}
//                             </select>
//                           </td>
//                           <td>
//                             <Form.Check
//                               type="switch"
//                               name="trading"
//                               checked={sig.trading == "1" ? true : false}
//                               onChange={(e) => {
//                                 serviceChange(e, index);
//                               }}
//                             />
//                           </td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 </div>
//                 <div className="row">
//                   <div className="col-md-10 pr-1">
//                       <button
//                         type="button"
//                         onClick={handleSubmit}
//                         className="btn btn-color btn-block"
//                       >
//                         Update
//                       </button>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       {showAlert && (
//         <AlertToast
//           hideAlert={onAlertClose}
//           showAlert={showAlert}
//           message={textAlert}
//           alertColor={alertColor}
//         />
//       )}
//     </>
//   );
// }

// export default Dashboard;


import React, { useEffect, useMemo, useState } from "react";
import { BrowserRouter, Routes, Route, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Form } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import * as Config from "../common/Config";
import AlertToast from "../common/AlertToast";
import Backdrop from '@mui/material/Backdrop';
import * as Constant from "../common/ConstantMessage";
import CircularProgress from '@mui/material/CircularProgress';

function Dashboard() {
  let navigate = useNavigate();
  const [services, setServices] = useState([]);
  const [strategy, setStrategy] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [textAlert, setTextAlert] = useState("");
  const [alertColor, setAlertColor] = useState("");
  const [refreshscreen, setRefreshscreen] = useState(true);
  const [orderTypeValue, setOrderTypeValue] = useState("");
  const [rerender, setRerender] = useState()
  const [loader, setLoader] = useState(false);
  const [clientDeatils, setClientDetails] = useState("");


  const client_token = localStorage.getItem('client_token');
  const client_id = localStorage.getItem("client_id");
  const roleId = localStorage.getItem("roleId");

  const fromadmin = localStorage.getItem('from_admin');
  const fromsubadmin = localStorage.getItem('from_subadmin');

  var user_id = localStorage.getItem("client_id");
  var data_array = [];
  var updatedata_array = [];



  //console.log("to_month",  clientDeatils.to_month)
 

  const ClientDetails = () => {
    axios({
      url: `${Config.base_url}client/profile`,
      method: "POST",
      data: {
        client_id: client_id,
      },
      headers: {
        'x-access-token': client_token
      }
    })
      .then((res) => {
        if (res.data) {
          // console.log("daaaaata", res.data);
          setClientDetails(res.data.msg);

        }
      });
  };

  useEffect(() => {
    ClientDetails()
  }, []);



  useEffect(() => {
    setLoader(true)
    axios({
      url: `${Config.base_url}client/services`,
      method: "post",
      data: {
        client_id: user_id,
      },
      headers: {
        'x-access-token': client_token
      }
    }).then((res) => {

      setStrategy(res.data.strategy);
      setServices(res.data.services);

      setLoader(false)
    });
  }, [rerender]);

  const onAlertClose = (e) => {
    setShowAlert(false);
  };

  // const onShowClick = (id) => {
  //   if (window.confirm(Constant.DELETE_GROUP_CONFIRM_MSG)) {
  //     axios({
  //       method: "post",
  //       url: `${Config.base_url}smartalgo/group-services/delete`,
  //       data: { id: id },
  //     }).then(function (response) {
  //       setRefreshscreen(!refreshscreen);
  //     });
  //   }
  // };

  const setAllToSetStrategy = (e) => {
    if (e.target.value === "all") {
      setRerender(!rerender)
    }
    setServices(
      services.map((item, index) => {
        item.strategy = e.target.value
        return item
      }
      )
    )

  }


  const serviceChange = (e, id) => {
    //  if (e.target.value == "") {

    //     setServices(
    //       services.map(
    //         (item, index) => (item.qty = index == id ? "" : item)
    //       )
    //     );
    //     return
    //   } 
    if (e.target.name == "qty") {

      console.log('qty', typeof e.target.value);
      const re = /^[0-9\b]+$/;

      // else {
      setServices(
        services.filter(
          (item, index) => {
            if (index == id) {
              item.qty = e.target.value
            } else {
              item.qty = item.qty
            }
            return item
          }
        )
      );
      // }
    }

    if (e.target.name == "cstrategy") {
      setServices(
        services.filter(
          (item, index) =>
            (item.strategy = index == id ? e.target.value : item.strategy)
        )
      );
    }

    if (e.target.name == "order_type") {

      setServices(
        services.filter(
          (item, index) =>
            (item.order_type = index == id ? e.target.value : item.order_type)
        )
      );
    }

    if (e.target.name == "product_type") {
      setOrderTypeValue(e.target.value);
      setServices(
        services.filter(
          (item, index) =>
          (item.product_type =
            index == id ? e.target.value : item.product_type)
        )
      );
    }

    if (e.target.name == "trading") {
      setServices(
        services.filter((item, index) => {
          if (index == id) {
            item.trading = item.trading == 0 ? 1 : 0;
          }

          return item;
        })
      );
    }

  };

  const handleSubmit = () => {
    const ServiceArr = services.map((item) => {
      // console.log("itemitem", clientDeatils.show_update_qty);
      // clientDeatils.show_update_qty === 0 && sig.ser_name === 'FINNIFTY' ? 80 : clientDeatils.show_update_qty === 0 && sig.ser_name === 'NIFTY' ? 100 : clientDeatils.show_update_qty === 0 && sig.ser_name === 'BANKNIFTY' ? 30 : ''

      // if (clientDeatils && clientDeatils.show_update_qty === 0) {
      if (clientDeatils.licence_type === 2 && (clientDeatils && clientDeatils.show_update_qty === 0 && item.ser_name == "BANKNIFTY") && item.qty > 60) {
        alert("you can't update more then 60 in BANKNIFTY")
        return
      } if (clientDeatils.licence_type === 2 && (clientDeatils && clientDeatils.show_update_qty === 0 && item.ser_name == "FINNIFTY") && item.qty > 80) {
        alert("you can't update more then 80 in FINNIFTY")
        return
      } if (clientDeatils.licence_type === 2 && (clientDeatils && clientDeatils.show_update_qty === 0 && item.ser_name == "NIFTY") && item.qty > 100) {
        alert("you can't update more then 100 in NIFTY")
        return
      }
      // return
      // }

      if (item.qty == 0 || item.qty == "" || item.qty == null) {
        item.qty = 1;
      }
      if (item.strategy == 'null' || strategy.length == 1) {
        item.strategy = strategy[0].strategy

      } else if (strategy.length > 0) {
        return item
      }
      return item

    })

    console.log("test", ServiceArr);

    axios({
      method: "post",
      url: `${Config.base_url}client/updateservices`,
      data: {
        client_signal: ServiceArr,
        client_id: user_id,
      },
      headers: {
        'x-access-token': client_token
      }
    }).then(function (response) {
      if (response) {
        setShowAlert(true);
        setAlertColor("success");
        setTextAlert(Constant.UPDATED_SUCCESS);
        // navigate('../client/Dashboard.js')
      }
    });
  };
  // console.log("ssssssss",services);


  return (
    <>
      <Backdrop
        sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="content content-light">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title font">
                   {/* {
                    roleId==4 && (clientDeatils && clientDeatils.to_month == 0)?"subadmin":"admin"
                   } */}
                  Dashboard
                  
                  </h4>
              </div>
              <div className="card-body">
                {/*  <div className="row">
                  <div className="col-md-4 ">
                    <b>Set All Strategy</b>
                    <select
                      name="cstrategy"
                      // value={sig.strategy}
                      onChange={(e) => {
                        setAllToSetStrategy(e);
                      }}
                      className="form-control spacing"
                      style={{ width: "100px" }}
                    >
                      <option value="all">All</option>
                      {strategy && strategy.map((star, i) => (
                        <option value={star.strategy} key={i}>{star.strategy}</option>
                      ))}
                    </select>
                  </div>
                </div> */}

                <div className="table-responsive tableheight" >
                  <table className="table tbl-tradehostory">
                    <thead className="tablecolor">
                      <tr className="fontbold">
                        <th className="fontbold">No.</th>
                        <th className="fontbold">Symbol</th>
                        <th>Quantity</th>
                        <th>Segment</th>
                        <th width="180"  >Strategy</th>
                        <th>Order Type</th>
                        <th>Product Type</th>
                        <th>Trading</th>
                      </tr>
                    </thead>
                    <tbody>
                      {services && services.map((sig, index) => (
                        <tr key={index}>

                          <td>{index + 1}</td>
                          <td>{sig.ser_name}</td>
                          <td>
                            <input
                              name="qty"
                              disabled={roleId==4 && (clientDeatils && clientDeatils.to_month > 0)?true:false}
                              type="number" pattern="^[0-9\b]+$"
                              onChange={(e) => {
                                serviceChange(e, index);
                              }}
                              style={{ width: "150px" }}
                              value={sig.qty}
                              className="form-control"
                              // min="1"      
                              max={clientDeatils.licence_type === 2 && (clientDeatils.show_update_qty === 0 && sig.ser_name === 'FINNIFTY' ? 80 : clientDeatils.show_update_qty === 0 && sig.ser_name === 'NIFTY' ? 100 : clientDeatils.show_update_qty === 0 && sig.ser_name === 'BANKNIFTY' ? 30 : '')}
                            />
                          </td>
                          <td>{sig.cat_name}</td>
                          <td>
                            <select
                              name="cstrategy"
                              disabled={roleId==4 && (clientDeatils && clientDeatils.to_month > 0) ?true:false}
                              value={sig.strategy}
                              onChange={(e) => {
                                serviceChange(e, index);
                              }}
                              className="form-control"
                              style={{ width: "100%" }}
                            >

                              {strategy && strategy.map((star, i) => (
                                <option key={i}>{star.strategy}</option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <select
                              className="order_type form-control"
                              name="order_type"
                              disabled={roleId==4 && (clientDeatils && clientDeatils.to_month > 0) ?true:false}
                              value={sig.order_type}
                              onChange={(e) => {
                                serviceChange(e, index);
                              }}

                              style={{ width: "100px" }}
                            >
                              {sig.product_type == "1" ||
                                sig.product_type == "2" ? (
                                <>
                                  <option value="1">MARKET</option>
                                  {/* <option value="2">LIMIT</option>
                                  <option value="3">STOPLOSS LIMIT</option>
                                  <option value="4">STOPLOSS MARKET</option> */}
                                </>
                              ) : sig.product_type == "3" ? (
                                <>
                                  <option value="2">LIMIT</option>
                                  <option value="3">STOPLOSS LIMIT</option>
                                </>
                              ) : sig.product_type == "4" ? (
                                <>
                                  <option value="1">MARKET</option>
                                  <option value="2">LIMIT</option>
                                </>
                              ) : (
                                ""
                              )}
                            </select>
                          </td>
                          <td>
                            <select
                              className="product_type form-control"
                              name="product_type"
                              disabled={roleId==4 && (clientDeatils && clientDeatils.to_month > 0) ?true:false}
                              value={sig.product_type}
                              onChange={(e) => {
                                serviceChange(e, index);
                              }}
                              style={{ width: "100px" }}
                            >
                              {/* <option value="1">CNC</option> */}
                              <option value="2">MIS</option>
                              {/* <option value="3">BO</option> */}
                              {/* <option value="4">CO</option> */}
                            </select>
                          </td>
                          <td>
                            <Form.Check
                              type="switch"
                              name="trading"
                              disabled={roleId==4 && (clientDeatils && clientDeatils.to_month > 0) ?true:false}
                              checked={sig.trading == "1" ? true : false}
                              onChange={(e) => {
                                serviceChange(e, index);
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col-md-10 pr-1">
                    {/* {fromadmin == null && */}
                    <button
                      type="button"
                      onClick={handleSubmit}
                      className="btn btn-color btn-block"
                    >
                      Update
                    </button>
                    {/* } */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showAlert && (
        <AlertToast
          hideAlert={onAlertClose}
          showAlert={showAlert}
          message={textAlert}
          alertColor={alertColor}
        />
      )}
    </>
  );
}

export default Dashboard;

